
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import DisplayMode from '@/mixins/DisplayMode';
import DisplayIdentifier from '@/mixins/DisplayIdentifier';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import { OpeningHour } from '@/interfaces/models/OpeningHour';
import { Article } from '@/interfaces/models/Article';
import { Tag } from '@/interfaces/models/Tag';

@Component({
  components: { VOpeningHour, VFormBuilder },
})
export default class CategoryForm extends mixins(StackedForm, DisplayMode, DisplayIdentifier) {
  @Prop({ type: Array }) public articles!: Array<Partial<Article>>;
  @Prop({ type: Array, required: true, default: [] }) public tags!: Tag[];
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
    hours: InstanceType<typeof VOpeningHour> & { hours: OpeningHour[] };
  };

  get items() {
    return [
      { name: 'assets', type: InputType.File, label: 'category.form.image', default: null, accept: 'image/*' },
      { name: 'icon', type: InputType.File, label: 'category.form.icon', default: null, accept: 'image/*' },
      { name: 'name', type: InputType.Language, useTextarea: true, label: 'category.form.name', rules: 'required' },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'category.form.description' },
      { name: 'sortOrder', type: InputType.Text, label: 'category.form.sort', rules: 'integer' },
      {
        name: 'mainCategory',
        type: InputType.Select,
        label: 'category.form.mainCategory',
        items: this.mainCategories,
        rules: 'required',
      },
      {
        name: 'mainRecommendations',
        type: InputType.Autocomplete,
        label: 'category.form.mainRecommendations',
        multiple: true,
        items: this.articleItems,
        useChips: true,
      },
      {
        name: 'tags',
        type: InputType.Select,
        label: 'option.form.tags',
        items: this.tagItems,
        multiple: true,
      },
      {
        name: 'displayMode',
        type: InputType.Select,
        label: 'category.form.displayMode',
        items: this.displayModes,
        default: 'default',
      },
      {
        name: 'displayIdentifiers',
        type: InputType.Select,
        label: 'category.form.displayIdentifiers',
        items: this.displayIdentifiers,
        multiple: true,
      },
      { name: 'visible', type: InputType.Checkbox, label: 'category.form.visible', default: true },
    ];
  }

  get mainCategories() {
    return [
      { value: 'dish', text: this.$t('mainCategories.dish') },
      { value: 'beverage', text: this.$t('mainCategories.beverage') },
      { value: 'other', text: this.$t('mainCategories.other') },
    ];
  }

  get articleItems() {
    if (this.articles && this.articles.length > 0) {
      return this.articles.map((o: Partial<Article>, index) => {
        return {
          value: o._id,
          text: this.$options.filters!.localized(o.name),
        };
      });
    } else {
      return [];
    }
  }

  get tagItems() {
    return this.tags.map((t: Tag) => {
      return {
        value: t._id,
        text: this.$options.filters!.localized(t.name),
      };
    });
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.assets === null) {
      delete data.assets;
    }
    if (data.icon === null) {
      delete data.icon;
    }

    // @ts-ignore
    if (this.initialValues) {
      if (
        // @ts-ignore
        this.initialValues.assets &&
        // @ts-ignore
        this.initialValues.assets.thumb &&
        // @ts-ignore
        this.initialValues.assets.thumb.length > 0 &&
        filesToRemove.indexOf('assets') > -1
      ) {
        data.assets = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.icon &&
        // @ts-ignore
        this.initialValues.icon.thumb &&
        // @ts-ignore
        this.initialValues.icon.thumb.length > 0 &&
        filesToRemove.indexOf('icon') > -1
      ) {
        data.icon = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return { ...data };
  }
}
