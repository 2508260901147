
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import DisplayMode from '../../mixins/DisplayMode';
import DisplayIdentifier from '../../mixins/DisplayIdentifier';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';

@Component({
  components: { VFormBuilder },
})
export default class FeatureForm extends mixins(StackedForm, DisplayMode, DisplayIdentifier) {
  public $refs!: {
    features: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  get items() {
    return [
      {
        name: 'new',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.new',
      },
      {
        name: 'special',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.special',
      },
      {
        name: 'topSelling',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.features.topSelling',
      },
    ];
  }

  public getData() {
    return {
      features: { ...this.$refs.features.form },
    };
  }
}
