
import { Component } from 'vue-property-decorator';
import CategoryForm from '@/components/category/CategoryForm.vue';
import EditCategoryLoader from '@/components/category/EditCategoryLoader.vue';
import { Category } from '@/interfaces/models/Category';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import { Tag } from '@/interfaces/models/Tag';
import Filter from '@/interfaces/api/Filter';
import FeatureForm from '@/components/category/FeatureForm.vue';
import EditLocalization from '@/mixins/EditLocalization';
import { Permission } from '@/enums/Permission';
import AvailableHourForm from '@/components/category/form/AvailableHourForm.vue';
import { Article } from '@/interfaces/models/Article';

const venue = namespace('venue');
const foodcard = namespace('foodcard');
const tag = namespace('tag');

@Component({
  components: { FeatureForm, AvailableHourForm, AvailabilityForm, VWrapper, EditCategoryLoader, CategoryForm },
})
export default class EditCategory extends mixins(Editable, StackedForm, Notification, EditLocalization) {
  @venue.State('active') public venue!: Venue;

  @venue.Action('triggerRefresh') public refreshVenue!: any;

  @foodcard.State('categories') public categories!: Category[];
  @foodcard.State('activeCategory') public active!: Category;

  @foodcard.Action('storeCategory') public store!: any;
  @foodcard.Action('updateCategory') public update!: any;
  @foodcard.Action('setActiveCategory') public setActive!: any;
  @foodcard.Action('uploadCategoryImage') public uploadImage!: any;
  @foodcard.Action('uploadCategoryIcon') public uploadIcon!: any;
  @foodcard.Action('fetchArticleNames') public getArticleNames: any;
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;

  @tag.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @tag.Action('fetch') public getTags!: any;
  @tag.State('items') public tags!: Tag[];

  public $refs!: {
    form: InstanceType<typeof CategoryForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
    features: InstanceType<typeof AvailableHourForm> & { getData: () => any };
  };

  get category() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'category.edit' : 'category.create';
  }

  public beforeMount() {
    if (this.editing && this.venue) {
      this.$startLoading('category');
      this.setActive((this.categories as Category[]).find((item: Category) => item._id === this.id)!);
    }
  }

  public async mounted() {
    if (this.editing && (!this.venue || !this.active)) {
      this.$router.push({ name: 'category.index' });
    }
    if (!this.canChangeCoupon()) {
      this.$router.push({ name: 'category.index' });
    }
    this.$stopLoading('category');
    this.$startLoading('tags');
    this.$stopLoading('articleNames');
    if (this.venue && this.venue._id) {
      this.setFilter({ venue: this.venue._id });
      await this.getArticleNames({ venue: this.venue._id });
      await this.getTags();
    }
    this.$stopLoading('tags');
    this.$stopLoading('articleNames');
  }

  public canChangeCoupon() {
    if (this.$isAdmin()) return true;

    if (this.active?.name?.de === 'Coupon') {
      if (this.active.visible) {
        return this.$can(Permission.CATEGORY_COUPON_VISIBLE);
      }
      return this.$can(Permission.CATEGORY_COUPON_HIDE);
    }

    return true;
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const data: Partial<Category> = {
          venue: this.venue._id,
          ...this.$refs.form.getData(),
          ...this.$refs.availableHours.getData(),
          ...(this.$refs.features ? this.$refs.features.getData() : {}),
          availability: {
            ...this.$refs.availability.getData(),
          },
        };
        if (this.editing) {
          if (this.active && this.active.name && data.name) {
            data.name = this.serializeLocalization(this.active.name, data.name);
          }
          await this.update({ id: this.id, ...data });
        } else {
          await this.store(data);
        }

        if (!this.active) {
          this.$router.push({ name: 'category.index' });
          return;
        }
        let formData: FormData;
        const uploadMeta: { id: string; venue: string } = { id: this.active._id, venue: this.venue._id };

        if (data.assets && data.assets instanceof File) {
          formData = new FormData();
          formData.append('asset', data.assets as Blob);
          await this.uploadImage({ ...uploadMeta, image: formData });
        }

        if (data.icon && data.icon instanceof File) {
          formData = new FormData();
          formData.append('asset', data.icon as Blob);
          await this.uploadIcon({ ...uploadMeta, icon: formData });
        }
        this.refreshVenue();
        this.$router.push({ name: 'category.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
