import { Component, Vue } from 'vue-property-decorator';
import { Localization } from '@/interfaces/models/Localization';

@Component
export default class EditLocalization extends Vue {
  // for deleting item should be empty field for key language
  public serializeLocalization(original: Localization, edited: Localization): Localization {
    Object.keys(original).map((key: string) => {
      if (key === '_id' || key === 'updatedAt') {
        return;
      }
      if (!edited.hasOwnProperty(key)) {
        edited[key as keyof Localization] = '';
      }
    });
    return edited;
  }
}
